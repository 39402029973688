import { faArchive, faBars, faCircleUser, faDoorOpen, faShoppingCart, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useAuthenticatedUser } from "../../context/AuthContext";
import { recalculateCartItems, useShoppingCart } from "../../context/ShoppingCartContext";
import { checkTokenExpiration, showHeaderAndFooter } from "../../utils/function";
import "./header.scss";

interface HeaderProps {
    setSignInOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
    setMobileSideNavigationCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Header = ({ setSignInOffCanvasShow, setMobileSideNavigationCanvasShow }: HeaderProps) => {
    const [cartIsShowing, setCartIsShowing] = useState(true);
    const { authMember, isLoggedIn, logout } = useAuthenticatedUser();
    const { openCart, cartQuantity, cartItems, setCartItems, clearCart, allProducts } = useShoppingCart();
    let location = useLocation();

    useEffect(() => {
        if (!location.pathname.includes("products")) {
            setCartIsShowing(false);
        } else {
            setCartIsShowing(true);
        }
        var expiredToken = checkTokenExpiration(logout);
        if (expiredToken) {
            clearCart();
        }
        showHeaderAndFooter(location.pathname);
    }, [location.key]);

    const logoutWithCartConvert = () => {
        if (cartItems.length > 0) {
            recalculateCartItems(false, cartItems, setCartItems, allProducts);
        }
        logout();
    };

    return (
        <div className="header border-bottom" id="main-header">
            <>
                <Container className="py-1 d-flex flex-wrap justify-content-between justify-md-content-center container align-items-center border-md-none px-4 px-md-0 px-lg-5">
                    <Link to="/" className="d-flex align-items-center me-lg-auto link-body-emphasis text-decoration-none">
                        <img
                            src={"https://topshelfnutra.com/main.svg"}
                            alt="TopShelfNutra Logo"
                            className="d-inline-block align-text-top"
                            style={{ width: "100px" }}
                        />
                    </Link>

                    {/* Mobile view */}
                    <div className="d-flex align-items-center gap-3 gap-md-4">
                        {cartIsShowing && (
                            <div onClick={openCart} className="d-block d-lg-none  fs-2 cursor-pointer nav-link link-body-emphasis px-2">
                                <div className="cursor-pointer text-center lh-1">
                                    <FontAwesomeIcon className="fs-3" icon={faShoppingCart} />
                                    <p className="cart-text mb-1 d-xl-block small mt-2 fs-6">Cart ({cartQuantity})</p>
                                </div>
                            </div>
                        )}
                        {!location.pathname.includes("challenge") && (
                            <FontAwesomeIcon
                                onClick={() => setMobileSideNavigationCanvasShow(true)}
                                className="d-block d-lg-none fs-2 cursor-pointer"
                                icon={faBars}
                            />
                        )}
                    </div>
                    {/* End */}
                    {authMember && isLoggedIn ? (
                        <div className="d-none d-lg-block d-lg-flex align-items-center gap-3">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {/* <BootstrapDropdown
                                    value={authMember.email}
                                    dropdownItems={[
                                        { icon: <FontAwesomeIcon icon={faArchive} />, to: "account/", title: "My Account" },
                                        {
                                            icon: <FontAwesomeIcon icon={faDoorOpen} />,
                                            to: "/",
                                            title: "Log out",
                                            hasDivider: true,
                                            onClick: () => logoutWithCartConvert(),
                                        },
                                    ]}
                                /> */}
                                <div className="d-flex align-items-center">
                                    <div className="header__membership">{authMember.email.toLowerCase()}</div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="header__membership">Membership:</div>
                                    <div className={`px-1 header__membership ${authMember.active ? "tsn-green" : "tsn-red"}`}>
                                        {authMember.active ? "Active" : "Inactive"}
                                    </div>
                                </div>
                            </div>
                            {cartIsShowing && (
                                <div onClick={openCart} className="nav-link link-body-emphasis px-2">
                                    <div className="cursor-pointer text-center lh-1">
                                        <FontAwesomeIcon className="fs-3" icon={faShoppingCart} />
                                        <p className="mb-1 d-xl-block small mt-2 fs-6">Cart ({cartQuantity})</p>
                                    </div>
                                </div>
                            )}
                            <Link onClick={logoutWithCartConvert} to="/" className="nav-link link-body-emphasis px-2">
                                <div className="cursor-pointer text-center lh-1">
                                    <FontAwesomeIcon className="fs-3" icon={faDoorOpen} />
                                    <p className="mb-1 d-xl-block small mt-2 fs-6">Log out</p>
                                </div>
                            </Link>
                        </div>
                    ) : (
                        <div className="d-none d-lg-block">
                            {!location.pathname.includes("challenge") && (
                                <ul className="nav">
                                    <li className="nav-item member-signed-in-remove">
                                        <a onClick={() => setSignInOffCanvasShow(true)} className="nav-link link-body-emphasis px-2">
                                            <div className="cursor-pointer text-center  lh-1">
                                                <FontAwesomeIcon className="fs-3" icon={faCircleUser} />
                                                <p className="mb-1 d-xl-block small mt-2">Sign In</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="nav-item member-signed-in-remove">
                                        <Link to="/account/signup" className="nav-link link-body-emphasis px-2">
                                            <div className="cursor-pointer text-center lh-1">
                                                <FontAwesomeIcon className="fs-3" icon={faUserPlus} />
                                                <p className="mb-1 d-xl-block small mt-2">Sign Up</p>
                                            </div>
                                        </Link>
                                    </li>
                                    {cartIsShowing && (
                                        <li className="nav-item member-signed-in-remove">
                                            <a onClick={openCart} className="nav-link link-body-emphasis px-2">
                                                <div className="cursor-pointer text-center lh-1">
                                                    <FontAwesomeIcon className="fs-3" icon={faShoppingCart} />
                                                    <p className="mb-1 d-xl-block small mt-2 fs-6">Cart ({cartQuantity})</p>
                                                </div>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            )}
                        </div>
                    )}
                </Container>
                {!location.pathname.includes("challenge") && (
                    <div className="d-none d-md-block header__banner d-flex flex-wrap">
                        <Container>
                            <ul className="nav me-auto">
                                <li className="nav-item">
                                    <Link to="/" aria-current="page" className="nav-link text-white">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="products/" className="nav-link text-white  px-2">
                                        Product Catalog
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="about/" className="nav-link text-white  px-2">
                                        Our Story
                                    </Link>
                                </li>
                                {authMember && isLoggedIn && (
                                    <li className="nav-item">
                                        <Link to="account/" className="nav-link text-white  px-2">
                                            My Account
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </Container>
                    </div>
                )}
            </>
        </div>
    );
};
