import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { useAuthenticatedUser } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { BootstrapButton } from "../../components/BootstrapButton/BootstrapButton";
import "./product-details-page.scss";
import { MembershipInfoOffCanvas } from "../../components/MembershipInfoOffCanvas/MembershipInfoOffCanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface ProductDetailsPageProps {}

export const ProductDetailsPage = ({}: ProductDetailsPageProps) => {
    const searchParams = useParams();
    const { allProducts, getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart } = useShoppingCart();
    const { authMember, isLoggedIn } = useAuthenticatedUser();
    const [photoType, setPhotoType] = useState<"_label" | "_ingredients" | "_supports">("_label");
    const [labelImageIsValid, setLabelImageIsValid] = useState(true);
    const [ingredientImageIsValid, setIngredientImageIsValid] = useState(true);
    const [supportsImageIsValid, setSupportsImageIsValid] = useState(true);
    const [membershipInfoOffCanvasShow, setMembershipInfoOffCanvasShow] = useState(false);

    const product = allProducts.find((product) => product.id === searchParams.id);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (product) {
        const quantity = getItemQuantity(product.id);
        return (
            <>
                <Container className="w-100 mt-4 mb-4">
                    <div className="d-flex mt-2 mb-4">
                        <Link to="/products/" className="fs-6 text-primary fw-bold btn-catalog">
                            Catalog
                        </Link>
                        <div className="ms-2 fs-6"> / {product.name}</div>
                    </div>
                </Container>

                <Container>
                    <div className="product-details-container flex-column-reverse flex-md-row row mb-4">
                        <div className="col-md-6 col-sm-12">
                            <div className="d-flex flex-column">
                                <div className="d-none d-md-block container-detail-image">
                                    {(
                                        photoType === "_label"
                                            ? labelImageIsValid
                                            : photoType === "_ingredients"
                                            ? ingredientImageIsValid
                                            : supportsImageIsValid
                                    ) ? (
                                        <img
                                            src={`/${product.id}${photoType == "_label" ? "_label.JPG" : `${photoType}.png`}`}
                                            className="product-details-page__main-img card-image w-100 img-fluid object-fit-contain border rounded"
                                        />
                                    ) : (
                                        <img
                                            src={"https://topshelfnutra.com/main.svg"}
                                            style={{ height: "300px", objectFit: "contain" }}
                                            className="card-image w-100 mb-3 img-fluid object-fit-contain border rounded"
                                        />
                                    )}
                                </div>
                                <div className="d-flex flex-column flex-md-row container-image-options">
                                    {labelImageIsValid ? (
                                        <img
                                            onClick={() => setPhotoType("_label")}
                                            src={`/${product.id}_label.JPG`}
                                            alt={product.name}
                                            title="Label"
                                            className="product-details-page__option-img img-fluid justify-content-center p-3 border"
                                            onError={() => {
                                                setLabelImageIsValid(false);
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src={"https://topshelfnutra.com/main.svg"}
                                            style={{ height: "300px", objectFit: "contain" }}
                                            className="card-image w-100 mb-3 img-fluid object-fit-contain border rounded"
                                        />
                                    )}
                                    {ingredientImageIsValid && (
                                        <img
                                            onClick={() => setPhotoType("_ingredients")}
                                            src={`/${product.id}_ingredients.png`}
                                            alt={product.name}
                                            title="Ingredients"
                                            className="product-details-page__option-img img-fluid justify-content-center p-3 border"
                                            onError={() => {
                                                setIngredientImageIsValid(false);
                                            }}
                                        />
                                    )}
                                    {supportsImageIsValid && (
                                        <img
                                            onClick={() => setPhotoType("_supports")}
                                            src={`/${product.id}_supports.png`}
                                            alt={product.name}
                                            title="Supports"
                                            className="product-details-page__option-img img-fluid justify-content-center p-3 border"
                                            onError={() => {
                                                setSupportsImageIsValid(false);
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="d-flex flex-column ms-3">
                                <div className="fs-4 fw-bold mb-4">{product.name}</div>

                                {(product.id === "prod_RTkrAmOgfRMrlv" || product.id === "prod_RTkr7AJWSgqKeR") && (
                                    <ul>
                                        <li>BulaFit Fitness Program</li>
                                        {!authMember || !authMember.active && <li>1 60 Day Complimentary TSN Membership</li>}
                                        <li>1 BulaFit Shaker Bottle</li>
                                        <li>Resistance Workout Bands</li>
                                        <li>On-the-go Container</li>
                                        <li>Mindset 60 Program Enrollment</li>
                                        <li>1 GTM Heirloom Botanicals</li>
                                        <li>1 ATP Balance</li>
                                        <li>1 Detox Tea</li>
                                        <li>2 Vital Fuel Shakes</li>
                                        <li>{product.id === "prod_RTkrAmOgfRMrlv" ? "2 Sport N Go" : "1 Sport N Go"}</li>
                                        {product.id === "prod_RTkr7AJWSgqKeR" && <li>1 Java Boost Coffee</li>}
                                    </ul>
                                )}
                                {product.id === "prod_RWND0mRoOot4y8" && (
                                    <ul>
                                        <li>BulaFit Fitness Program</li>
                                        <li>Mindset 60 Program Enrollment</li>
                                        <li>Recognition and Awards</li>
                                    </ul>
                                )}

                                <div className="mt-4">
                                    <span className="fs-5 fw-bold">Retail:</span>
                                    <span className="fs-5 ms-2 text-primary">{product.retail_price.unit_amount_formatted}</span>
                                </div>
                                <div className="">
                                    <div className="d-flex fs-5 align-self-center">
                                        <div className="fw-bold">Member:</div>
                                        <div className="d-flex flex-column ms-2">
                                            <div className="fs-5  mb-2">
                                                First:<span className="ms-1 text-primary">{product.member_single_price.unit_amount_formatted}</span>
                                                <FontAwesomeIcon
                                                    onClick={() => setMembershipInfoOffCanvasShow(true)}
                                                    className="mx-2 fs-5 product-item__info"
                                                    icon={faInfoCircle}
                                                />
                                            </div>
                                            <div className="fs-5 mb-2">
                                                Each Additional:{" "}
                                                <span className="ms-1  text-primary">{product.member_multiple_price.unit_amount_formatted}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mb-4 w-100 w-md-50 w-lg-100 d-flex align-items-center justify-content-between mt-3"
                                    style={{ gap: ".5rem" }}
                                >
                                    {quantity === 0 ? (
                                        <>
                                            {product.is_coming_soon && <div className="product-item__out_of p-2 px-3">Coming Soon</div>}
                                            {!product.is_coming_soon && product.is_back_ordered && (
                                                <div className="product-item__out_of p-2 px-3">Out of Stock</div>
                                            )}
                                            {!product.is_back_ordered && !product.is_coming_soon && (
                                                <BootstrapButton
                                                    onClick={() => increaseCartQuantity(product)}
                                                    variant="secondary"
                                                    title="Add to Cart"
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-center" style={{ gap: ".5rem" }}>
                                            <BootstrapButton onClick={() => decreaseCartQuantity(product.id)} variant="secondary" title="-" />
                                            <div className="fs-6">
                                                <b>
                                                    <span className="fs-5">{quantity}</span>
                                                </b>
                                            </div>
                                            <BootstrapButton
                                                disabled={quantity === 10}
                                                onClick={() => increaseCartQuantity(product)}
                                                variant="secondary"
                                                title="+"
                                            />
                                        </div>
                                    )}
                                    {quantity > 0 && <BootstrapButton onClick={() => removeFromCart(product.id)} variant="danger" title="Remove" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <MembershipInfoOffCanvas
                    membershipInfoOffCanvasShow={membershipInfoOffCanvasShow}
                    setMembershipInfoOffCanvasShow={setMembershipInfoOffCanvasShow}
                />
            </>
        );
    } else {
        return null;
    }
};
