import { Col, Container, Row } from "react-bootstrap";
import { useAuthenticatedUser } from "../../../context/AuthContext";
import { BootstrapButton } from "../../../components/BootstrapButton/BootstrapButton";
import { StripeApi, Subscription } from "../../../apis/StripeApi";
import { SubscriptionOffCanvas } from "../../../components/SubscriptionOffCanvas/SubscriptionOffCanvas";
import { useEffect, useState } from "react";
import { CustomerApi } from "../../../apis/CustomerApi";
import { Member } from "../../../apis/MemberApi";
import { Bootstrap, Calendar2Check } from "react-bootstrap-icons";
import { PaymentMethod as StripePaymentMethod } from "@stripe/stripe-js";
import { PaymentMethod } from "../../../components/PaymentMethod/PaymentMethod";
import { AddCardOffCanvas } from "../../../components/AddCardOffCanvas/AddCardOffCanvas";
import { BootstrapInput } from "../../../components/BootstrapInput/BootstrapInput";
import "./membership.scss";
import { useNavigate } from "react-router-dom";

interface MembershipProps {
    membership: any;
    subscriptions: Subscription[];
    paymentMethods: StripePaymentMethod[];
    setPaymentMethods: React.Dispatch<React.SetStateAction<StripePaymentMethod[]>>;
    getMembershipDetails: () => Promise<void>;
    setLoadingAccountPage: React.Dispatch<React.SetStateAction<boolean>>;
    handleSetDefaultPaymentMethod: (customer_id: string, card_id: string) => Promise<void>;
}

export const Membership = ({
    subscriptions,
    membership,
    paymentMethods,
    setPaymentMethods,
    getMembershipDetails,
    setLoadingAccountPage,
    handleSetDefaultPaymentMethod,
}: MembershipProps) => {
    const { authMember, updateAuthMember } = useAuthenticatedUser();
    const [subscriptionOffCanvasShow, setSubscriptionOffCanvasShow] = useState(false);
    const [addCardOffCanvasShow, setAddCardOffCanvasShow] = useState(false);
    const [subscriptionClientSecret, setSubscriptionClientSecret] = useState("");
    const [cardClientSecret, setCardClientSecret] = useState("");
    const [sessionId, setSessionId] = useState("");
    const [confirmCancel, setConfirmCancel] = useState("");
    const [confirmCancelClick, setConfirmCancelClick] = useState(false);
    const [choosenSubscriptionName, setChoosenSubscriptionName] = useState("");
    const [removeMonthlySubscriptionOnComplete, setRemoveMonthlySubscriptionOnComplete] = useState(false);
    const navigate = useNavigate();

    const buyMembershipClick = async (subscription: Subscription, removeMembership: boolean = false) => {
        if (authMember) {
            CustomerApi.CreateSubscriptionSession(authMember.customer_id, subscription.default_price)
                .then((response) => {
                    setSubscriptionClientSecret(response.client_secret);
                    setSessionId(response.id);
                    setChoosenSubscriptionName(subscription.name);
                    if (removeMembership) {
                        setConfirmCancel("confirm");
                        setRemoveMonthlySubscriptionOnComplete(true);
                    }
                })
                .catch(console.error)
                .finally(() => {
                    setSubscriptionOffCanvasShow(true);
                });
        }
    };

    useEffect(() => {
        if (!subscriptionOffCanvasShow && sessionId.length > 0) {
            CustomerApi.ExpireCheckoutSession(sessionId);
            setSubscriptionClientSecret("");
            setSessionId("");
        }
        if (!subscriptionOffCanvasShow) {
            setCardClientSecret("");
            setRemoveMonthlySubscriptionOnComplete(false);
            setConfirmCancel("");
        }
    }, [subscriptionOffCanvasShow]);

    const handleIsMemberActive = async () => {
        if (authMember) {
            let activeCheck = await CustomerApi.CheckActiveMembership(authMember.customer_id);
            if (activeCheck.success && authMember) {
                updateAuthMember({ ...authMember, active: activeCheck.valid_member });
            } else {
                console.log("Something went wrong while checking for member status.");
            }
        }
    };

    const handleRemovePaymentMethod = async (customer_id: string, card_id: string) => {
        let response = await CustomerApi.RemovePaymentMethod(customer_id, card_id).catch(console.error);
        if (response && authMember) {
            let copyOfPaymentMethods = [...paymentMethods];
            copyOfPaymentMethods = copyOfPaymentMethods.filter((paymentMethod) => paymentMethod.id !== card_id);
            setPaymentMethods(copyOfPaymentMethods);
        }
    };

    const handleUpdatePaymentMethod = async (card_id: string, exp_month: string, exp_year: string, postal_code: string) => {
        let response = await CustomerApi.UpdatePaymentMethod(card_id, exp_month, exp_year, postal_code).catch(console.error);
        if (response && response.success && authMember) {
            getPaymentMethods();
        }
    };

    const getPaymentMethods = async () => {
        if (authMember) {
            let response = await CustomerApi.GetPaymentMethods(authMember.customer_id);
            if (response) {
                setPaymentMethods(response);
            }
        }
    };

    const addACardClick = async () => {
        if (authMember) {
            let response = await CustomerApi.SetupIntent(authMember?.customer_id).catch(console.error);
            if (response) {
                setCardClientSecret(response.client_secret);
                setAddCardOffCanvasShow(true);
            }
        }
    };

    const onCancelSubscriptionOnClick = async (membership: any) => {
        if (membership && membership.id && confirmCancel.toLowerCase() === "confirm" && authMember) {
            let response = await StripeApi.CancelSubscription(membership.id, authMember.member_id);
            console.log(response);
            if (response.success) {
                setLoadingAccountPage(true);
                getMembershipDetails();
            } else {
                console.log(response.message);
            }
        }
    };

    return (
        <>
            <AddCardOffCanvas
                addCardOffCanvasShow={addCardOffCanvasShow}
                setAddCardOffCanvasShow={setAddCardOffCanvasShow}
                clientSecret={cardClientSecret}
                getPaymentMethods={getPaymentMethods}
            />
            <SubscriptionOffCanvas
                subscriptionOffCanvasShow={subscriptionOffCanvasShow}
                setSubscriptionOffCanvasShow={setSubscriptionOffCanvasShow}
                clientSecret={subscriptionClientSecret}
                handleIsMemberActive={handleIsMemberActive}
                getMembershipDetails={getMembershipDetails}
                setLoadingAccountPage={setLoadingAccountPage}
                subscriptionName={choosenSubscriptionName}
                upgradeMonthlySubscription={{
                    upgrade: removeMonthlySubscriptionOnComplete,
                    cancelMembership: onCancelSubscriptionOnClick,
                    membershipToCancel: membership,
                }}
            />
            <Container fluid className="mt-3 mt-sm-3 mt-md-3 mt-lg-0 mb-5 wrapper-tab wrapper-affiliate d-block stage-container">
                <div className="fs-4 mb-4 fw-bold">Membership Information</div>
                {!authMember?.active ? (
                    <Row>
                        {subscriptions.map((subscription) => (
                            <Col key={subscription.id} className="my-4 col-12 d-flex flex-column">
                                <Row>
                                    <Col className="col-12 d-flex d-md-block justify-content-center" md={6}>
                                        <h4 className="fw-semibold mb-2 text-body-emphasis">{subscription.name}</h4>
                                    </Col>
                                    <Col className="col-12 d-flex d-md-block justify-content-center">
                                        <p className="text-body-secondary mb-2">{subscription.description}</p>
                                    </Col>
                                    <Col className="col-12 d-flex d-md-block justify-content-center" md={6}>
                                        <BootstrapButton
                                            onClick={() => buyMembershipClick(subscription)}
                                            variant="secondary"
                                            title={`Buy ${subscription.name}`}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <div className="col-12 mb-5 wrapper-tab wrapper-billing" data-navigation="billing">
                        {membership && (
                            <div className="wrapper-subscription-info">
                                <Row className="d-flex w-100">
                                    <Col md={6} className="col-12 fs-4 text-body-emphasis">
                                        {membership.metadata.preluanch_purchase
                                            ? "Pre-Launch One Year Membership"
                                            : membership.plan.interval === "year"
                                            ? "Yearly Membership"
                                            : "Monthly Membership"}
                                    </Col>
                                    <Col
                                        md={6}
                                        className="col-12 d-flex justify-content-start justify-content-md-end align-items-center fs-4 fw-bold ms-auto text-success"
                                    >
                                        <Calendar2Check className="me-2 mt-1 fs-4" />
                                        Active
                                    </Col>
                                    {subscriptions.find((sub) => sub.metadata?.is_yearly === "true") &&
                                        !membership.metadata.prelaunch_purchase &&
                                        membership.plan &&
                                        membership.plan.interval !== "year" &&
                                        membership.plan.product !== "prod_RTkuio8RiPFF0D" && (
                                            <>
                                                <Col className="mt-4 fs-5 member__upgrade_subscription_title">
                                                    <div
                                                        className="cursor-pointer"
                                                        onClick={() =>
                                                            buyMembershipClick(
                                                                subscriptions.find((sub) => sub.metadata?.is_yearly === "true") as Subscription,
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Upgrade to Yearly Subscription
                                                    </div>
                                                </Col>
                                                <Col className="col-12 fs-6 member__upgrade_subscription">
                                                    This will cancel your monthly subscription and upgrade your account to our yearly recurring
                                                    subscription. Our yearly renewed subscription comes with 30 PDC's upon purchase, <b>ONLY $180</b>{" "}
                                                    a year.
                                                </Col>
                                            </>
                                        )}
                                </Row>
                            </div>
                        )}
                    </div>
                )}
                {window.location.search.includes("updateCC=true") && (
                    <div className="w-100 my-3 tsn-red border p-3">
                        {window.location.search.includes("subscription=true")
                            ? "You have failed to make your subscription payment due to the card found in your email. Please update it to continue having an active TopShelfNutra Membership. If you no longer want to use this card, add a new one and set it to default. Note - We will run your card again in the next few days to try again."
                            : "You have a card that is expiring soon. Please remove or update the card found in your email."}
                    </div>
                )}
                <div className="w-100 my-5 wrapper-card-information d-block">
                    <Row className="d-flex w-100 border-bottom">
                        <Col md={9} className="col-12 fs-5 fw-bold mb-2">
                            Saved Payment Methods
                        </Col>
                        <Col md={3} className="my-3 my-md-0 col-12 ms-auto fs-6 d-flex justify-content-start justify-content-md-end">
                            <a onClick={addACardClick} className="cursor-pointer text-reset">
                                Add a Card
                            </a>
                        </Col>
                    </Row>
                    {authMember && (
                        <Row className="w-100">
                            {paymentMethods.length > 0 ? (
                                paymentMethods.map((method, i) => (
                                    <PaymentMethod
                                        key={`${method.created}_${i}`}
                                        authMember={authMember}
                                        method={method}
                                        handleSetDefaultPaymentMethod={handleSetDefaultPaymentMethod}
                                        handleRemovePaymentMethod={handleRemovePaymentMethod}
                                        handleUpdatePaymentMethod={handleUpdatePaymentMethod}
                                    />
                                ))
                            ) : (
                                <div className="wrapper-customer-cards w-100">
                                    <div className="fs-5">(No credit cards on file)</div>
                                </div>
                            )}
                        </Row>
                    )}
                </div>
                {membership && !membership.metadata.preluanch_purchase && (
                    <>
                        <div className="w-100 my-2 wrapper-card-information d-block">
                            <Row className="d-flex w-100 border-bottom">
                                <Col md={9} className="col-12 fs-5 fw-bold mb-2">
                                    Cancel Subscription
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col className="col-12" md={6} lg={6}>
                                {!confirmCancelClick ? (
                                    <BootstrapButton
                                        onClick={() => setConfirmCancelClick(true)}
                                        variant="outline-danger"
                                        title="Cancel Current Membership"
                                    />
                                ) : (
                                    <div>
                                        <div>
                                            Are you sure you want to cancel your membership? If so, type 'confirm' in the field below and click the
                                            confirm button.
                                        </div>
                                        <div className="mt-3 d-flex align-items-center">
                                            <BootstrapInput
                                                value={confirmCancel}
                                                onChange={(e) => setConfirmCancel(e.target.value)}
                                                placeholder="Type confirm"
                                            />
                                            <BootstrapButton
                                                onClick={() => {
                                                    setConfirmCancelClick(false);
                                                    setConfirmCancel("");
                                                }}
                                                className="ms-2"
                                                variant="secondary"
                                                title="Cancel"
                                            />
                                            <BootstrapButton
                                                onClick={() => confirmCancel.toLowerCase() === "confirm" && onCancelSubscriptionOnClick(membership)}
                                                className="ms-2"
                                                variant="outline-primary"
                                                title="Confirm"
                                            />
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
        </>
    );
};
