import { UUID } from "crypto";
import { ApiCaller } from "./ApiCaller";
import { CartItem } from "../context/ShoppingCartContext";

export class CustomerApi {
    private static apiCaller = new ApiCaller("customer");

    public static GetCustomer(customer_id: string) {
        return this.apiCaller.GET(`${customer_id}`);
    }

    public static CheckActiveMembership(customer_id: string) {
        return this.apiCaller.GET<any>(`checkActiveMembership/${customer_id}`);
    }

    public static PatchOneCustomer(customer_id: string, data: any) {
        return this.apiCaller.PATCH(`${customer_id}`, data);
    }

    public static SetupIntent(customer_id: string) {
        return this.apiCaller.POST(`setupIntent`, { customer_id });
    }

    public static ExpireCheckoutSession(session_id: string) {
        return this.apiCaller.POST(`expireCheckoutSession`, { session_id });
    }

    public static CreateSubAndCheckoutSession(
        line_items: CartItem[],
        customer_id: string,
    ) {
        return this.apiCaller.POST(`createSubAndCheckoutSession`, {
            line_items: line_items.map((item) => {
                return { price: item.price, quantity: item.quantity };
            }),
            customer_id,
        });
    }

    public static CreateSubscriptionSession(customer_id: string, price: string) {
        return this.apiCaller.POST(`createSubscriptionSession`, {
            customer_id,
            price,
        });
    }

    public static CreateCheckoutSession(
        line_items: CartItem[],
        customer_id: string | undefined = undefined,
        member_id: string | undefined = undefined,
        pdc_credit: number = 0
    ) {
        return this.apiCaller.POST(`createCheckoutSession`, {
            line_items: line_items.map((item) => {
                return { price: item.price, quantity: item.quantity };
            }),
            customer_id,
            pdc_credit,
            member_id
        });
    }

    public static GetPaymentMethods(customer_id: string) {
        return this.apiCaller.POST(`paymentMethods`, { customer_id });
    }

    public static SetDefaultPaymentMethod(customer_id: string, payment_id: string) {
        return this.apiCaller.POST(`setDefaultPaymentMethod`, { customer_id, payment_id });
    }

    public static RemovePaymentMethod(customer_id: string, payment_id: string) {
        return this.apiCaller.POST(`removePaymentMethod`, { customer_id, payment_id });
    }

    public static UpdatePaymentMethod(payment_id: string, exp_month: string, exp_year: string, postal_code: string) {
        return this.apiCaller.POST(`updatePaymentMethod`, { payment_id, exp_month, exp_year, postal_code });
    }
}
