import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Member } from "../apis/MemberApi";

const defaultState = {
    authMember: {},
    setAuthMember: (member: Member) => {},
    setIsLoggedIn: (trueOrFalse: boolean) => {},
    isLoggedIn: false,
} as MemberContextInterface;

export const AuthContext = createContext<MemberContextInterface>(defaultState);

interface AuthProviderProps {
    children: ReactNode;
}

export const useAuthenticatedUser = () => {
    return useContext(AuthContext);
};

export interface MemberContextInterface {
    setAuthMember: React.Dispatch<React.SetStateAction<Member | undefined>>;
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
    updateAuthMember: (newMemberObj: Member) => void;
    authMember: Member | undefined;
    isLoggedIn: boolean;
    logout: () => void;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [authMember, setAuthMember] = useState<Member>();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    const logout = () => {
        setAuthMember(undefined);
        setIsLoggedIn(false);
        localStorage.clear();
        sessionStorage.clear();
        navigate("/");
    };

    const updateAuthMember = (newMemberObj: Member) => {
        localStorage.setItem("member", JSON.stringify(newMemberObj));
        setAuthMember(newMemberObj);
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        const member = localStorage.getItem("member");
        if (token) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
        if (member) {
            let parsedMember: Member = JSON.parse(member);
            setAuthMember(parsedMember);
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{
                updateAuthMember,
                authMember,
                setAuthMember,
                isLoggedIn,
                setIsLoggedIn,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
